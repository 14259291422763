import { useRef } from "react";
import "./style.css";

export const Agency = () => {
  const ref = useRef(null);

  return (
    <section id="full_screen">
      <div className="section">
        <div ref={ref}>
          <p>
            <span className="marketera-name">Marketera Agency </span> is a
            forward-thinking marketing powerhouse dedicated to crafting
            <span> innovative strategies </span> that transform brands. With a
            focus on<span> high-quality </span>design and compelling content, we
            turn your marketing dreams into reality.
          </p>
        </div>
      </div>
      <div className="section">
        <div ref={ref}>
          <p>
            Founded in <span>2022</span>, Marketera started with a passion for
            <span> creativity</span> and a <span> commitment </span> to
            excellence. Over the years, we’ve partnered with diverse clients,
            helping them reach new heights. Our journey is marked by{" "}
            <span> successful </span> campaigns, industry recognition, and
            continuous growth.
          </p>
        </div>
      </div>
      <div className="section">
        <div ref={ref}>
          <p>
            We’re proud to have improved our clients’ reach by over{" "}
            <span> 200% </span>, with a <span> 95% </span> satisfaction rate.
            Our campaigns have consistently outperformed expectations, making
            our clients’ visions come to life.
          </p>
        </div>
      </div>
    </section>
  );
};
