import { Slider } from "../Slider";
import "./style.css";

export const Transformation = () => {
  return (
    <section id="transformation-section" className="main-section ">
      <div className="transformation">
        <div className="section-header">
          <h4 className="section-title">see the transformation</h4>
          <p className="section-desc">
            Explore real-life transformations where our strategies have driven
            significant growth and success for our clients. See the measurable
            results and envision what we can achieve together.
          </p>
        </div>
        <div className="section-content">
          <Slider />
        </div>
      </div>
    </section>
  );
};
