import "./style.css";

export const MouseScrolling = () => {
  return (
    <div className="scrolling-mouse">
      <div className="mouse"></div>
      <p>Scroll for more</p>
    </div>
  );
};
