import React, { useState } from "react";
import { FaBars, FaTimes, FaFacebook, FaInstagram } from "react-icons/fa";
import logo from "../../Assets/Marketera_logo.png";
import { Button } from "../Common/button";
import "./style.css";

export const Navbar = () => {
  const [menuActive, setMenuActive] = useState(false);

  const toggleMenu = () => {
    setMenuActive(!menuActive);
  };

  return (
    <nav className="navbar">
      <img src={logo} alt="Marketera Agency" height={50} className="logo" />
      <div className={`menu ${menuActive ? "active" : ""}`}>
        <img
          src={logo}
          alt="Marketera Agency"
          height={50}
          className="menu-logo"
        />
        <div className="menu-item">
          <a href="#full_screen">about</a>
        </div>
        <div className="menu-item">
          <a href="#services-section">Services</a>
        </div>
        <div className="menu-item">
          <a href="#projects-section">Designs</a>
        </div>
        <div className="menu-item">
          <a href="#transformation-section">Case Studies</a>
        </div>
        <a href="http://wa.me/+96181353109" target="_blank">
          <Button
            name={"Call Us"}
            className={"button"}
            style={{ padding: "20px 35px", fontSize: "22px" }}
          />
        </a>
        <div className="navbar_social_media">
          <a
            href="https://www.facebook.com/profile.php?id=61552309376269"
            target="_blank"
          >
            <FaFacebook size={30} className="social-icon" />
          </a>
          <a href="https://www.instagram.com/marketera_agency/" target="_blank">
            <FaInstagram size={30} className="social-icon" />
          </a>
        </div>
      </div>
      <div className="hamburger" onClick={toggleMenu}>
        {menuActive ? <FaTimes size={30} /> : <FaBars size={30} />}
      </div>
    </nav>
  );
};
