import "./style.css";

export const Values = () => {
    return (
        <section id="values-section" className="main-section">
            <div className="values">
                <div className="section-header">
                    <hr className="header-line" />
                    <h4 className="section-title">
                        values we live by
                    </h4>
                    <p className="section-desc">
                        At Marketera, our values are the foundation of everything we do. They
                        guide our decisions, inspire our creativity, and shape the way we
                        interact with our clients and community. We believe in innovation,
                        quality, and a client-first approach, ensuring that we consistently
                        deliver exceptional results. Discover what drives us and how we make
                        your vision a reality.
                    </p>
                </div>

                <div className="section-content">
                    <div className="value-item">
                        <h5 className="value-number">01</h5>
                        <h6 className="value-title">innovation</h6>
                        <p className="section-desc">
                            We stay ahead of trends, using the latest tools and techniques to
                            deliver cutting-edge solutions.
                        </p>
                    </div>

                    <div className="value-item">
                        <h5 className="value-number">02</h5>
                        <h6 className="value-title">quality</h6>
                        <p className="section-desc">
                            Every project is a masterpiece, combining creativity and precision.
                        </p>
                    </div>

                    <div className="value-item">
                        <h5 className="value-number">03</h5>
                        <h6 className="value-title">client-centric</h6>
                        <p className="section-desc">
                            Your success is our top priority. We listen, adapt, and deliver.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};
