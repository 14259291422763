import { useEffect, useState } from 'react';
import { useAnimation, motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const Counter = ({ targetNumber, text }) => {
    const controls = useAnimation();
    const [ref, inView] = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });

    const [count, setCount] = useState(0);

    useEffect(() => {
        if (inView) {
            controls.start({
                count: targetNumber,
                transition: { duration: 2, ease: 'easeOut' },
            });
        }
    }, [controls, inView, targetNumber]);

    return (
        <motion.h4 ref={ref} animate={controls} initial={{ count: 0 }} onUpdate={(latest) => setCount(Math.floor(latest.count))}>
            {count}{text}
        </motion.h4>
    );
};

export default Counter;