import { MouseScrolling } from "../MouseScrolling";
import "./style.css";

export const Slogan = () => {
    return (
        <div className="banner-scroll">
            <div id="banner">
                <div className="banner-content">
                    <h1 className="banner-heading">
                        Helping Business<span> Grow </span>on Social Media Platforms.
                    </h1>
                    <h5 className="banner-subheading">
                        We Make It <span>{`${"Real"}`}</span>
                    </h5>
                </div>
            </div>
            <MouseScrolling />
        </div>
    );
};
