import { Button } from "../../Components/Common/button";
import "./style.css";

export const CallToAction = () => {
    return (
        <section id="call-to-action" className="main-section">
            <div className="call-to-action">
                <div className="section-header">
                    <p className="section-subtitle">let's talk</p>
                    <h4 className="section-title">
                        Ready to Transform Your Brand?
                    </h4>
                </div>
                <div>
                    <a href="http://wa.me/+96181353109" target="_blank" rel="noreferrer">
                        <Button name={"book a call"} className={"button"} style={{ padding: "20px 35px", fontSize: "22px" }} />
                    </a>
                </div>
            </div>
        </section>
    );
};
