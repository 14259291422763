import SibaVideo from "../../Assets/SIbaVideo.mp4";
import coverImage from "../../Assets/WhatsApp Image 2024-06-30 at 11.37.32 PM 1.png";
import BackgroundImage from "../../Assets/section-background-2100.png";
import BackgroundImage1 from "../../Assets/section_background.png";
import BackgroundImage2 from "../../Assets/section-background-1280.png";
import BackgroundImage3 from "../../Assets/section-background-830.png";
import BackgroundImage4 from "../../Assets/section-background-600.png";
import BackgroundImage5 from "../../Assets/section-background-480.png";
import "./style.css";

export const Services = () => {
    return (
        <section id="services-section" className="main-section">
            <img className="services-img-2100" src={BackgroundImage} alt="background section"/>
            <img className="services-img-1725" src={BackgroundImage1} alt="background section"/>
            <img className="services-img-1280" src={BackgroundImage2} alt="background section"/>
            <img className="services-img-830" src={BackgroundImage3} alt="background section"/>
            <img className="services-img-600" src={BackgroundImage4} alt="background section"/>
            <img className="services-img-430" src={BackgroundImage5} alt="background section"/>
            <div className="services">
                <div className="section-header">
                    <h4 className="section-title">
                        How we can help you
                    </h4>
                    <p className="section-desc">
                        We bring your vision to life with tailored marketing strategies and
                        impactful content. Discover how we can elevate your brand and drive
                        results.
                    </p>
                </div>

                <div className="services-content">
                    <ul className="services-list">
                        <li>
                            <h5 className="services-title">• social media management</h5>
                            <p className="services-desc">
                                Engaging your audience and growing your online presence
                                through tailored social strategies.
                            </p>
                        </li>
                        <li>
                            <h5 className="services-title">• content creation</h5>
                            <p className="services-desc">
                                Crafting compelling content that resonates with your audience
                                and elevates your brand.
                            </p>
                        </li>
                        <li>
                            <h5 className="services-title">• branding</h5>
                            <p className="services-desc">
                                Building a strong, cohesive brand identity that sets you apart
                                from the competition.
                            </p>
                        </li>
                        <li>
                            <h5 className="services-title">• web development</h5>
                            <p className="services-desc">
                                Designing responsive, user-friendly websites that captivate
                                and convert visitors.
                            </p>
                        </li>
                        <li>
                            <h5 className="services-title">• marketing strategy</h5>
                            <p className="services-desc">
                                Developing data-driven strategies that drive growth and
                                maximize your ROI.
                            </p>
                        </li>
                        <li>
                            <h5 className="services-title">• ads manager</h5>
                            <p className="services-desc">
                                Creating and managing targeted ad campaigns to boost your
                                visibility and sales.
                            </p>
                        </li>
                        <li>
                            <h5 className="services-title">• post design</h5>
                            <p className="services-desc">
                                Designing eye-catching social media posts that capture
                                attention and enhance engagement.
                            </p>
                        </li>
                        <li>
                            <h5 className="services-title">• photography</h5>
                            <p className="services-desc">
                                Capturing stunning images that tell your brand’s story and
                                connect with your audience.
                            </p>
                        </li>
                        <li>
                            <h5 className="services-title">• video editing</h5>
                            <p className="services-desc">
                                Producing high-quality videos that engage, inform, and
                                entertain your target market.
                            </p>
                        </li>
                        <li>
                            <h5 className="services-title">• 3D animation videos</h5>
                            <p className="services-desc">
                                Creating immersive 3D animations that bring your ideas to life
                                and captivate viewers.
                            </p>
                        </li>
                        <li>
                            <h5 className="services-title">• account security</h5>
                            <p className="services-desc">
                                Ensuring your social media accounts are protected and secure
                                from potential threats.
                            </p>
                        </li>
                    </ul>
                    <div className="services-video">
                        <div className="video">
                            <video
                                controls
                                poster={coverImage}
                            >
                                <source src={SibaVideo} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
