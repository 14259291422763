import Our_Work1 from "../../Assets/some-work/socialmedia1.jpg";
import Our_Work2 from "../../Assets/some-work/socialmedia2.jpg";
import Our_Work3 from "../../Assets/some-work/socialmedia3.jpeg";
import Our_Work4 from "../../Assets/some-work/socialmedia4.jpeg";
import Our_Work5 from "../../Assets/some-work/socialmedia5.jpeg";
import Our_Work6 from "../../Assets/some-work/socialmedia6.jpeg";
import Our_Work7 from "../../Assets/some-work/socialmedia7.jpeg";
import Our_Work8 from "../../Assets/some-work/socialmedia8.jpeg";
import Our_Work9 from "../../Assets/some-work/socialmedia9.jpeg";
import Our_Work10 from "../../Assets/some-work/socialmedia10.jpeg";
import Our_Work11 from "../../Assets/some-work/socialmedia11.jpeg";
import Our_Work12 from "../../Assets/some-work/socialmedia12.jpeg";
import Our_Work13 from "../../Assets/some-work/socialmedia13.jpeg";
import Our_Work14 from "../../Assets/some-work/socialmedia14.jpeg";
import Our_Work15 from "../../Assets/some-work/socialmedia15.jpeg";
import { Button } from "../../Components/Common/button";
import "./style.css";

export const Projects = () => {
  return (
    <section id="projects-section" className="main-section">
      <div className="projects">
        <div className="section-header">
          <hr className="header-line" />
          <h4 className="section-title">Some of our work</h4>
          <p className="section-desc">
            Take a look at some of our standout projects that showcase the
            creativity and results Marketera delivers. From innovative campaigns
            to brand transformations, explore how we've helped our clients
            achieve success.
          </p>
        </div>

        <div className="section-content">
          <div className="project-item">
            <div className="project-image">
              <img src={Our_Work1} alt="project thumbnail" />
            </div>
            <h5 className="project-title">Achel Alwen</h5>
            <p className="project-desc">
              Engaging your audience and growing your online presence through
              tailored social strategies.
            </p>
          </div>
          <div className="project-item">
            <div className="project-image">
              <img src={Our_Work2} alt="project thumbnail" />
            </div>
            <h5 className="project-title">Speed Car Wash</h5>
            <p className="project-desc">
              Engaging your audience and growing your online presence through
              tailored social strategies.
            </p>
          </div>
          <div className="project-item">
            <div className="project-image">
              <img src={Our_Work3} alt="project thumbnail" />
            </div>
            <h5 className="project-title">Pistachio Chicken</h5>
            <p className="project-desc">
              Engaging your audience and growing your online presence through
              tailored social strategies.
            </p>
          </div>
          <div className="project-item">
            <div className="project-image">
              <img src={Our_Work4} alt="project thumbnail" />
            </div>
            <h5 className="project-title">Jihan Tripoli</h5>
            <p className="project-desc">
              Engaging your audience and growing your online presence through
              tailored social strategies.
            </p>
          </div>
          <div className="project-item">
            <div className="project-image">
              <img src={Our_Work5} alt="project thumbnail" />
            </div>
            <h5 className="project-title">Awwad Bakery</h5>
            <p className="project-desc">
              Engaging your audience and growing your online presence through
              tailored social strategies.
            </p>
          </div>

          <div className="project-item">
            <div className="project-image">
              <img src={Our_Work6} alt="project thumbnail" />
            </div>
            <h5 className="project-title">Amigo</h5>
            <p className="project-desc">
              Engaging your audience and growing your online presence through
              tailored social strategies.
            </p>
          </div>
          <div className="project-item">
            <div className="project-image">
              <img src={Our_Work7} alt="project thumbnail" />
            </div>
            <h5 className="project-title">North Floral </h5>
            <p className="project-desc">
              Engaging your audience and growing your online presence through
              tailored social strategies.
            </p>
          </div>
          <div className="project-item">
            <div className="project-image">
              <img src={Our_Work8} alt="project thumbnail" />
            </div>
            <h5 className="project-title">Take a Break</h5>
            <p className="project-desc">
              Engaging your audience and growing your online presence through
              tailored social strategies.
            </p>
          </div>

          <div className="project-item">
            <div className="project-image">
              <img src={Our_Work9} alt="project thumbnail" />
            </div>
            <h5 className="project-title">Pretty Pearl</h5>
            <p className="project-desc">
              Engaging your audience and growing your online presence through
              tailored social strategies.
            </p>
          </div>
          <div className="project-item">
            <div className="project-image">
              <img src={Our_Work10} alt="project thumbnail" />
            </div>
            <h5 className="project-title">CoCo AlHarik</h5>
            <p className="project-desc">
              Engaging your audience and growing your online presence through
              tailored social strategies.
            </p>
          </div>
          <div className="project-item">
            <div className="project-image">
              <img src={Our_Work11} alt="project thumbnail" />
            </div>
            <h5 className="project-title">Ward</h5>
            <p className="project-desc">
              Engaging your audience and growing your online presence through
              tailored social strategies.
            </p>
          </div>
          <div className="project-item">
            <div className="project-image">
              <img src={Our_Work12} alt="project thumbnail" />
            </div>
            <h5 className="project-title">ElHaj Medical Center</h5>
            <p className="project-desc">
              Engaging your audience and growing your online presence through
              tailored social strategies.
            </p>
          </div>
          <div className="project-item">
            <div className="project-image">
              <img src={Our_Work13} alt="project thumbnail" />
            </div>
            <h5 className="project-title">Dr, Jalal</h5>
            <p className="project-desc">
              Engaging your audience and growing your online presence through
              tailored social strategies.
            </p>
          </div>
          <div className="project-item">
            <div className="project-image">
              <img src={Our_Work14} alt="project thumbnail" />
            </div>
            <h5 className="project-title">Aivda Leb</h5>
            <p className="project-desc">
              Engaging your audience and growing your online presence through
              tailored social strategies.
            </p>
          </div>
          <div className="project-item">
            <div className="project-image">
              <img src={Our_Work15} alt="project thumbnail" />
            </div>
            <h5 className="project-title">Ghafour</h5>
            <p className="project-desc">
              Engaging your audience and growing your online presence through
              tailored social strategies.
            </p>
          </div>
          <div style={{ textAlign: "center", width: "100%" }}>
            <a
              href="https://www.instagram.com/marketera_agency"
              target="_blank"
            >
              <Button className={"button"} name={"view more"} />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};
