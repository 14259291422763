import { Navbar } from "./Components";
import {
  Home,
  Agency,
  Projects,
  Services,
  Transformation,
  Values,
  Statistics,
  CallToAction,
  Footer,
} from "./Pages";
import ScrollToTop from "./Components/ScrollToTop";
import "./App.css";

function App() {
  return (
    <>
      <Navbar />
      <Home />
      <Agency />
      <Values />
      <Statistics />
      <Transformation />
      <Services />
      <Projects />
      <CallToAction />
      <Footer />
      <ScrollToTop />
    </>
  );
}

export default App;
