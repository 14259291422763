import Counter from "../../Components/Common/counter";
import "./style.css";

export const Statistics = () => {
    return (
        <section id="statistics-section" className="main-section">
            <div className="statistics">
                <div className="statistics-item">
                    <Counter targetNumber={200} text={"%"} />
                    <p>Increase in Reach</p>
                </div>

                <hr className="statistics-line" />

                <div className="statistics-item">
                    <Counter targetNumber={50} text={"+"} />
                    <p>Successful Campaigns Launched</p>
                </div>

                <hr className="statistics-line" />

                <div className="statistics-item">
                    <Counter targetNumber={300} text={"%"} />
                    <p>Increase in Engagement</p>
                </div>

                <hr className="statistics-line" />

                <div className="statistics-item">
                    <Counter targetNumber={2500} text={"+"} />
                    <p>Increase in Engagement</p>
                </div>

                <hr className="statistics-line" />

                <div className="statistics-item">
                    <Counter targetNumber={1000000} text={"+"} />
                    <p>Content Views</p>
                </div>
            </div>
        </section>
    );
};
