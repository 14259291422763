export const Button = ({ className, onClick, name, ...props }) => {
  return (
    <button
      className={className}
      onClick={onClick}
      {...props}
    >
      {name}
    </button>
  );
};
