import React, { useState } from "react";
import { motion } from "framer-motion";
import "./style.css";

export const FlipCard = ({ image }) => {
  const [isFlipped, setIsFlipped] = useState(false);

  const handleMouseEnter = () => {
    setIsFlipped(true);
  };

  const handleMouseLeave = () => {
    setIsFlipped(false);
  };

  return (
    <motion.div
      className="flip-card-inner"
      animate={{ rotateY: isFlipped ? 180 : 0 }}
      onHoverStart={handleMouseEnter}
      onHoverEnd={handleMouseLeave}
      initial={{ rotateY: 0 }}
      transition={{ duration: 0.1 }}
    >
      <div className="flip-card-front">
        <img src={image.src} alt={image.alt} className="Swiper_Slide_Image" />
      </div>
      <div className="flip-card-back">
        <img
          src={image.backSrc}
          alt={image.alt}
          className="Swiper_Slide_Image"
        />
      </div>
    </motion.div>
  );
};
