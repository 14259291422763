import { useRef } from "react";
import { Slogan } from "../../Components/Slogan";
import { motion, useTransform, useScroll } from "framer-motion";
import "./style.css";

export const Home = () => {
    const sectionRef = useRef(null);
    const { scrollYProgress } = useScroll({
        target: sectionRef,
        offset: ["start start", "end start"]
    });

    // Define the scaling effect based on the scroll position
    const scale = useTransform(scrollYProgress, [0, 1, 1], [1, 300, 300]);
    const zIndex = useTransform(scrollYProgress, [0, 1, 1], [2, -2, -2]);
    const opacity = useTransform(scrollYProgress, [0, 1, 1], [1, 1, 0]);

    return (
        <>
            <section ref={sectionRef} style={{ position: 'relative', height: '150vh', overflow: "hidden" }}>
                <motion.div
                    className="banner-img"
                    style={{
                        scale: scale,
                        zIndex: zIndex,
                        opacity: opacity,
                    }}
                />
                <div style={{ height: '100vh'}}>
                    <Slogan />
                </div>
            </section>
        </>
    );
};
